dmx.Component('checkbox', {

    extends: 'form-element',

    initialData: {
        checked: false
    },

    tag: 'input',

    attributes: {
        checked: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        select: function(check) {
            this.select(check);
        }
    },

    events: {
        checked: Event,
        unchecked: Event
    },

    render: function(node) {
        dmx.Component('form-element').prototype.render.call(this, node);
        this.$node.addEventListener('click', this.updateData.bind(this));
        this.$node.type = 'checkbox';
        this.$node.checked = this.props.checked;
        this.$node.defaultChecked = this.props.checked;
        this.set('checked', this.props.checked);
    },

    update: function(props, fields) {
        dmx.Component('form-element').prototype.update.call(this, props, fields);

        if (props.checked !== this.props.checked) {
            this.$node.checked = this.props.checked;
            this.$node.defaultChecked = this.props.checked;
        }

        this.updateData();
    },

    updateData: function(event) {
        dmx.Component('form-element').prototype.updateData.call(this, event);
        if (this.data.checked != this.$node.checked) {
            dmx.nextTick(function() {
                this.dispatchEvent('updated');
                if (event) {
                    this.dispatchEvent('changed');
                    this.dispatchEvent(this.$node.checked ? 'checked' : 'unchecked')
                }
            }, this);
        }
        this.set('checked', this.$node.checked);
    },

    select: function(check) {
        this.$node.checked = (check !== false);
    }

});
