dmx.Attribute('show', 'mounted', function(node, attr) {
    var orgValue = node.style.getPropertyValue('display');
    var orgPriority = node.style.getPropertyPriority('display');

    this.$addBinding(attr.value, function(value) {
        if (!value) {
            node.style.setProperty('display', 'none', 'important');
        } else {
            node.style.setProperty('display', orgValue, orgPriority);
        }
    })
});
