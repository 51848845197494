dmx.Component('value', {

    initialData: {
        value: null
    },

    attributes: {
        value: {
            default: null
        }
    },

    methods: {
        setValue: function(value) {
            if (this.data.value !== value) {
                this.set('value', value);
                dmx.nextTick(function() {
                    this.dispatchEvent('updated');
                }, this);
            }
        }
    },

    events: {
        updated: Event
    },

    render: function() {
        this.set('value', this.props.value);
    },

    update: function(props, fields) {
        if (fields.has('value')) {
            this.set('value', this.props.value);
            dmx.nextTick(function() {
                this.dispatchEvent('updated');
            }, this);
        }
    }

});
