dmx.global = {
    data: {},
    seed: Math.random(),
    get: function(name) {
        if (this.data.hasOwnProperty(name)) {
            return this.data[name];
        }

        return undefined;
    },
    set: function(name, value) {
        if (typeof name == 'object') {
            for (var prop in name) {
                if (name.hasOwnProperty(prop)) {
                    this.set(prop, name[prop]);
                }
            }
            return;
        }

        if (!dmx.equal(this.data[name], value)) {
            this.data[name] = value;
            dmx.requestUpdate(name);
        }
    },
    del: function(name) {
        delete this.data[name];
        dmx.requestUpdate(name);
    }
}

dmx.DataScope = function(data, parent) {
    if (typeof data != 'object') {
        data = { $value: data };
    }

    return {
        parent: parent || dmx.global,
        data: data,
        seed: Math.random(),
        get: function(name) {
            if (this.data.hasOwnProperty(name)) {
                return this.data[name];
            }

            if (this.parent) {
                if (name == 'parent') {
                    return this.parent.data;
                }

                return this.parent.get(name);
            }

            return undefined;
        },
        set: function(name, value) {
            if (typeof name == 'object') {
                for (var prop in name) {
                    if (name.hasOwnProperty(prop)) {
                        this.set(prop, name[prop]);
                    }
                }
                return;
            }
    
            if (!dmx.equal(this.data[name], value)) {
                this.data[name] = value;
            }
        },
        del: function(name) {
            delete this.data[name];
        }
    };
};