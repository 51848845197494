dmx.Attribute('repeat', 'before', function(node, attr) {
    if (this.node != node) {
        var component = this;
        var prevCount = 0;
        var children = [];
        var template = document.createDocumentFragment();
        var placeholder = document.createComment('Repeat ' + attr.value);
        var RepeatItem = dmx.Component('repeat-item');
        var name = attr.argument || 'repeat';

        node.parentNode.replaceChild(placeholder, node);
        node.removeAttribute('dmx-repeat');

        template.appendChild(node);

        component.set(name, []);

        this.$addBinding(attr.value, function(repeat) {
            var RepeatItem = dmx.Component('repeat-item');
            var items = dmx.repeatItems(repeat);

            if (!attr.modifiers.fast) {
                var fragment = document.createDocumentFragment();

                children.forEach(function(child) {
                    component.children.splice(component.children.indexOf(child), 1);
                    child.$destroy();
                });

                children = [];

                for (var i = 0; i < items.length; i++) {
                    var child = new RepeatItem(template.cloneNode(true), component, items[i]);
                    child.$nodes.forEach(function(node) {
                        fragment.appendChild(node);
                        child.$parse(node);
                    });
                    children.push(child);
                    component.children.push(child);
                }

                placeholder.parentNode.insertBefore(fragment, placeholder);
            } else {
                if (children.length > items.length) {
                    // remove some children
                    children.splice(items.length).forEach(function(child) {
                        component.children.splice(component.children.indexOf(child), 1);
                        child.$destroy();
                    });
                }

                if (children.length) {
                    // update existing children
                    children.forEach(function(child, i) {
                        child.set(items[i]);
                    });
                }

                if (items.length > children.length) {
                    // add new children
                    var fragment = document.createDocumentFragment();

                    for (var i = children.length; i < items.length; i++) {
                        var child = new RepeatItem(template.cloneNode(true), component, items[i]);
                        child.$nodes.forEach(function(node) {
                            fragment.appendChild(node);
                            child.$parse(node);
                        });
                        children.push(child);
                        component.children.push(child);
                    }

                    placeholder.parentNode.insertBefore(fragment, placeholder);
                }
            }

            component.set(name, children.map(function(child) { return child.data }));
        });
    }
});
