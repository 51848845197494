dmx.Component('flow', {

    initialData: {
        $param: null,
        data: null,
        running: false,
        lastError: null
    },

    attributes: {
        src: {
            type: String,
            default: null
        },

        preload: {
            type: Boolean,
            default: false
        },

        autorun: {
            type: Boolean,
            default: false
        },

        params: {
            type: Object,
            default: {}
        }
    },

    methods: {
        run: function(param, throwError) {
            return this.run(param, throwError);
        },

        runSub: function(param) {
            return this.runSub(param);
        }
    },

    events: {
        start: Event,
        done: Event,
        error: Event
    },

    $parseAttributes: function(node) {
        dmx.BaseComponent.prototype.$parseAttributes.call(this, node);
        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (attr.name == 'param' && attr.argument) {
                this.$addBinding(attr.value, function(value) {
                    this.props.params[attr.argument] = value;
                });
            }
        }, this);
    },

    render: function(node) {
        if (this.props.src) {
            if (this.props.preload || this.props.autorun) {
                this.load(this.props.src);
            }
        } else {
            try {
                this.flow = (window.Hjson ? Hjson : JSON).parse(node.textContent);
                if (this.props.autorun) {
                    this.run();
                }
            } catch (e) {
                console.error(e);
                if (dmx.debug) {
                    console.debug(node.textContent);
                }
            }
        }
    },

    load: function(uri) {
        var self = this;

        return new Promise(function(resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                try {
                    self.flow = (window.Hjson ? Hjson : JSON).parse(xhr.responseText);
                    if (self.props.autorun) {
                        self.run();
                    }
                    resolve();
                } catch (e) {
                    console.error(e);
                    if (dmx.debug) {
                        console.debug(xhr.responseText);
                    }
                    reject(e);
                }
            }
            xhr.onabort = reject;
            xhr.onerror = reject;
            xhr.ontimeout = reject;
            xhr.open('GET', uri);
            xhr.send();
        });
    },

    runSub: function(param) {
        var self = this;

        if (!this.flow) {
            if (this.props.src) {
                return this.load(this.props.src).then(function() {
                    return dmx.Flow.run(self.flow, self);
                });
            }

            throw new Error('No flow');
        }

        return dmx.Flow.run(this.flow, this);
    },

    run: function(param, throwError) {
        var self = this;

        if (this.flow) {
            if (this.data.running) {
                console.info('Flow ' + this.name + ' is already running.');
                return;
            }

            this.set('running', true);
            this.set('log', {});
            this.set('$param', Object.assign({}, this.props.params, param));
            this.set('lastError', null);

            this.dispatchEvent('start');

            return dmx.Flow.run(this.flow, this).then(function(data) {
                self.set('running', false);
                self.set('data', data);
                self.dispatchEvent('done');
                if (dmx.debug) {
                    console.debug('done', data);
                }
                return data;
            }).catch(function(err) {
                self.set('running', false);
                self.set('lastError', err && err.message);
                self.dispatchEvent('error');
                //if (dmx.debug) {
                    // show error debug by default
                    console.error(err);
                //}
                if (throwError) {
                    throw err;
                }
            });
        } else if (this.props.src) {
            return this.load(this.props.src).then(function() {
                return self.run(param, throwError);
            });
        }
    }

});
