(function() {

  window.dmx = window.dmx || {};

  // unsupported types: ImageBitmap

  var LARGE_ARRAY_SIZE = 200;

  var toString = Object.prototype.toString;
  var hasOwnProperty = Object.prototype.hasOwnProperty;

  var reFlags = /\w*$/;
  var reTypedType = /^(?:Float(?:32|64)|(?:Int|Uint)(?:8|16|32)|Uint8Clamped)Array$/;

  var eq = function(value, other) {
    return value === other || (value !== value && other !== other);
  }

  var assocIndexOf = function(array, key) {
    var length = array.length;
    while (length--) {
      if (eq(array[length][0], key)) {
        return length;
      }
    }
    return -1;
  };

  var isKeyable = function(value) {
    var type = typeof value;
    return (type == 'string' || type == 'number' || type == 'symbol' || type == 'boolean')
      ? (value !== '__proto__')
      : (value === null);
  };

  var getMapData = function(data, key) {
    return isKeyable(key)
      ? data[typeof key == 'string' ? 'string' : 'hash']
      : data.map;
  };

  var Hash = function(entries) {
    var index = -1,
        length = entries == null ? 0 : entries.length;

    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  }

  Hash.prototype = {
    clear: function() {
      this.__data__ = Object.create(null);
      this.size = 0;
    },

    delete: function(key) {
      var result = this.has(key) && delete this.__data__[key];
      this.size -= result ? 1 : 0;
      return result;
    },

    get: function(key) {
      var result = this.__data__[key];
      return result === HASH_UNDEFINED ? undefined : result;
    },

    has: function(key) {
      return this.__data__[key] !== undefined;
    },

    set: function(key, value) {
      this.size += this.has(key) ? 0 : 1;
      this.__data__[key] = value === undefined ? HASH_UNDEFINED : value;
      return this;
    }
  }

  var ListCache = function(entries) {
    var index = -1;
    var length = entries == null ? 0 : entries.length;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  };

  ListCache.prototype = {
    clear: function() {
      this.__data__ = [];
      this.size = 0;
    },

    delete: function(key) {
      var data = this.__data__;
      var index = assocIndexOf(data, key);
      if (index < 0) {
        return false;
      }
      var lastIndex = data.length - 1;
      if (index == lastIndex) {
        data.pop();
      } else {
        data.splice(index, 1);
      }
      --this.size;
      return true;
    },

    get: function(key) {
      var data = this.__data__;
      var index = assocIndexOf(data, key);
      return index < 0 ? undefined : data[index][1];
    },

    has: function(key) {
      return assocIndexOf(this.__data__, key) > -1;
    },

    set: function(key, value) {
      var data = this.__data__;
      var index = assocIndexOf(data, key);
      if (index < 0) {
        ++this.size;
        data.push([key, value]);
      } else {
        data[index][1] = value;
      }
      return this;
    }
  };

  var MapCache = function(entries) {
    var index = -1;
    var length = entries == null ? 0 : entries.length;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  };

  MapCache.prototype = {
    clear: function() {
      this.size = 0;
      this.__data__ = {
        'hash': new Hash(),
        'map': new Map(),
        'string': new Hash()
      };
    },

    delete: function(key) {
      var result = getMapData(this.__data__, key)['delete'](key);
      this.size -= result ? 1 : 0;
      return result;
    },

    get: function(key) {
      return getMapData(this.__data__, key).get(key);
    },

    has: function(key) {
      return getMapData(this.__data__, key).has(key);
    },

    set: function(key, value) {
      var data = getMapData(this.__data__, key);
      var size = data.size;
      data.set(key, value);
      this.size += data.size == size ? 0 : 1;
      return this;
    }
  };

  var Stack = function(entries) {
    var data = this.__data__ = new ListCache(entries);
    this.size = data.size;
  };

  Stack.prototype = {
    clear: function() {
      this.__data__ = new ListCache();
      this.size = 0;
    },

    delete: function(key) {
      var data = this.__data__;
      var result = data['delete'](key);
      this.size = data.size;
      return result;
    },

    get: function(key) {
      return this.__data__.get(key);
    },

    has: function(key) {
      return this.__data__.has(key);
    },

    set: function(key, value) {
      var data = this.__data__;
      if (data instanceof ListCache) {
        var pairs = data.__data__;
        if (pairs.length < LARGE_ARRAY_SIZE - 1) {
          pairs.push([key, value]);
          this.size = ++data.size;
          return this;
        }
        data = this.__data__ = new MapCache(pairs);
      }
      data.set(key, value);
      this.size = data.size;
      return this;
    }
  };

  var arrayEach = function(array, iteratee) {
    var index = -1;
    var length = array.length;
    while (++index < length) {
      if (iteratee(array[index], index, array) === false) {
        break;
      }
    }
    return array;
  };

  var getType = function(value) {
    return toString.call(value).slice(8, -1);
  };

  var isArray = function(value) {
    return Array.isArray(value);
  };

  var isObject = function(value) {
    return value != null && typeof value == 'object'
  };

  var isTypedArray = function(value) {
    return isObject(value) && reTypedType.test(getType(value));
  };

  var assignValue = function(object, key, value) {
    // Prototype polution protection
    if (key == '__proto__') return;

    var objValue = object[key];

    if (!(hasOwnProperty.call(object, key) && eq(objValue, value))) {
      //if (value !== 0 || (1 / value) === (1 / objValue)) {
        object[key] = value;
      //}
    } else if (value === undefined && !(key in object)) {
      object[key] = value;
    }
  };

  var cloneArrayBuffer = function(arrayBuffer) {
    var result = new arrayBuffer.constructor(arrayBuffer.byteLength);
    new Uint8Array(result).set(new Uint8Array(arrayBuffer));
    return result;
  };

  var cloneDataView = function(dataView) {
    var buffer = cloneArrayBuffer(dataView.buffer);
    return new dataView.constructor(buffer, dataView.byteOffset, dataView.byteLength);
  };

  var cloneTypedArray = function(typedArray) {
    var buffer = cloneArrayBuffer(typedArray.buffer);
    return new typedArray.constructor(buffer, typedArray.byteOffset, typedArray.length);
  };

  var cloneRegExp = function(regexp) {
    var result = new regexp.constructor(regexp.source, reFlags.exec(regexp));
    result.lastIndex = regexp.lastIndex;
    return result;
  };

  var cloneImageData = function(imageData) {
    var data = cloneTypedArray(imageData.data);
    return new imageData.constructor(data, imageData.width, imageData.height);
  }

  var initArray = function(array) {
    return new array.constructor(array.length);
  };

  var initType = function(object, type) {
    var Ctor = object.constructor;

    switch (type) {
      case 'ArrayBuffer':
        return cloneArrayBuffer();
      
      case 'Boolean':
      case 'Date':
        return new Ctor(+object);

      case 'DataView':
        return cloneDataView(object);

      case 'Float32Array':
      case 'Float64Array':
      case 'Int8Array':
      case 'Int16Array':
      case 'Int32Array':
      case 'Uint8Array':
      case 'Uint8ClampedArray':
      case 'Uint16Array':
      case 'Uint32Array':
        return cloneTypedArray(object);

      case 'Map':
      case 'Set':
        return new Ctor();

      case 'Number':
      case 'String':
        return new Ctor(object);

      case 'RegExp':
        return cloneRegExp(object);

      case 'ImageData':
        return cloneImageData(object);
    }
  };

  var clone = function(value, key, object, stack) {
    var result;

    if (!isObject(value)) {
      return value;
    }
    
    var type = getType(value);

    if (isArray(value)) {
      result = initArray(value);
    } else {
      if (type == 'Object') {
        result = {};
      } else {
        result = initType(value, type);
      }
    }  
    
    stack = stack || new Stack();

    var stacked = stack.get(value);
    if (stacked) {
      return stacked;
    }
    stack.set(value, result);

    if (type == 'Map') {
      value.forEach(function(subValue, key) {
        result.set(key, clone(subValue, key, value, stack));
      });

      return result;
    }

    if (type == 'Set') {
      value.forEach(function(subValue) {
        result.add(clone(subValue, subValue, value, stack));
      });

      return result;
    }

    if (isTypedArray(value)) {
      return result;
    }

    var props = isArray(value) ? undefined : Object.keys(Object(value));
    arrayEach(props || value, function(subValue, key) {
        if (props) {
          key = subValue;
          subValue = value[key];
        }
        assignValue(result, key, clone(subValue, key, value, stack));
    });

    return result;
  };

  dmx.clone = clone;

})();