dmx.Component('toggle', {

  initialData: {
    checked: false
  },

  attributes: {
    checked: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    check: function() {
      if (!this.data.checked) {
        this.set('checked', true);
        this.isUpdated();
      }
    },

    uncheck: function() {
      if (this.data.checked) {
        this.set('checked', false);
        this.isUpdated();
      }
    },

    toggle: function() {
      this.set('checked', !this.data.checked);
      this.isUpdated();
    }
  },

  events: {
      updated: Event
  },

  render: function() {
      this.set('checked', this.props.checked);
  },

  update: function(props) {
    if (props.checked != this.props.checked) {
      this.set('checked', this.props.checked);
      this.isUpdated();
    }
  },

  isUpdated: function() {
    dmx.nextTick(function() {
      this.dispatchEvent('updated');
    }, this);
  }

});
